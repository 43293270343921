export default {
    data() {
        return {}
    },
    methods: {
        hasFieldAfterTable(item, index) {
            let afterIndex = -1;


            for (let i = index - 1; i >= 0; i--) {
                if (this.fieldsData[i].inputType == "DATA_TABLE" || this.fieldsData[i].inputType == "ENTITY_TABLE" ) {
                    afterIndex = i;
                    break;
                }
            }

            if (afterIndex > -1) {
                return [this.fieldsData[afterIndex], afterIndex];
            }
            return [null, -1];
        },

        getStyle(item, index) {
            let zIndex = this.hideFields.filter(hf => hf == item.key).length ? -1 : 0

            if (this.interactiveQuestionsMode) {
                if (this.interactiveQuestionsModeType == "vertical") {
                    return `position:relative;left:${50}px;top:${this.getFieldYValueFromHeight(
                        item
                    )}px;height:${item.height}px;width:${item.width}px;` + (zIndex == -1 ? 'z-index : -1;' : '');
                } else {
                    return `position:absolute;left:150px;top:50px;height:${item.height}px;width:${item.width}px;` + (zIndex == -1 ? 'z-index : -1;' : '');
                }
            } else {
                let [dataTableField, dataTableFieldIndex] = this.hasFieldAfterTable(
                    item,
                    index
                );


                if (dataTableField) {
                    item.listen_height = true;

                    let cDiff = 0;
                    let cActualDiff = 0;



                    while (dataTableFieldIndex > -1) {

                        let elements = document.getElementsByClassName(
                            "dataTable" + dataTableFieldIndex
                        );


                        if (elements[0]) {
                            let currentHeight = elements[0].offsetHeight;
                            if (elements[0].offsetHeight === 0) {
                                currentHeight = this.fieldsData[dataTableFieldIndex].height;
                            } else {
                                currentHeight = elements[0].offsetHeight;
                            }


                            let diff = currentHeight - currentHeight;
                            item.listen_height = true;


                            let actualDiff = this.fieldsData[dataTableFieldIndex].height - currentHeight;

                            cDiff += diff
                            cActualDiff += actualDiff
                        }
                        dataTableFieldIndex--;
                    }


                    if (cDiff > 0) {
                        return `position:absolute;left:${item.x}px;top:${item.y +
                            cDiff +
                            40}px !important; height:${item.height}px;width:${item.width}px;` + (zIndex == -1 ? 'z-index : -1;' : '');
                    }
                    else {    
                        return `position:absolute;left:${item.x}px;top:${item.y -
                            cActualDiff +
                            20}px;height:${item.height}px;width:${item.width}px;` + (zIndex == -1 ? 'z-index : -1;' : '');
                    }
                } else {
                    return `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;` + (zIndex == -1 ? 'z-index : -1;' : '');
                }
            }
        }
    }
}